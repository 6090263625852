// @flow
import React, { type Node } from 'react';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { border_radius } from '../utils/styles';
import type { ImageType } from '../utils/types';

const Container = styled.figure`
  margin-bottom: 1.66667rem;
`;

const StyledImage = styled(GatsbyImage)`
  ${border_radius};
`;

const Caption = styled.figcaption`
  color: rgba(0, 0, 0, 0.5);
`;

type Props = {
  image: ImageType,
  caption?: Node,
  title?: string,
  alt?: string,
};

export default class Image extends React.Component<Props> {
  render() {
    const { image, caption, title, alt, ...rest } = this.props;
    return (
      <Container {...rest}>
        <StyledImage
          title={title}
          alt={alt}
          fluid={image.childImageSharp.fluid}
        />
        {caption && <Caption>{caption}</Caption>}
      </Container>
    );
  }
}
