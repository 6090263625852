// @flow
import React from 'react';
import Layout from '../components/layout';
import Link from '../components/link';
import GlossaryLink from '../components/glossary-link';
import type { FrontMatter, ImageType } from '../utils/types';
import { withFrontMatter } from '../components/front-matter';
import { graphql } from 'gatsby';
import Image from '../components/image';
import route from '../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {
    opengraph: ImageType,
    toss_n_go: ImageType,
    stacked_overhand_bend: ImageType,
    bad_idea: ImageType,
    safety: ImageType,
    throttle: ImageType,
    rope_twist: ImageType,
  },
};

class Canyoneering_Pull extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          Better start this off with a sweeping generalization: No true
          canyoneer has never gotten their rope stuck. It's a rite of passage
          into canyoneering so don't be embarrassed that you've stuck your rope.
          If you haven't then you need to go more often.
        </p>
        <p>
          My goal with this article is to go over the most common reasons people
          get their ropes stuck for all the different ways of rigging. I will
          also go over some of my solutions to these problems.
        </p>
        <h2>Stuck Bends and Blocks</h2>
        <h3>Bend stuck on edge</h3>
        <p>
          There are two possible solutions to the problem of a{' '}
          <GlossaryLink>bend</GlossaryLink> getting stuck on a edge.
        </p>
        <h4>Prevent bend from going over an edge</h4>
        <p>
          The first and most obvious is to put the knot in a place where it
          isn't a problem. If it is a <GlossaryLink>short rappel</GlossaryLink>,
          you can completely eliminate the need for the bend by going{' '}
          <GlossaryLink>toss-n-go</GlossaryLink>.
        </p>
        <Image
          image={data.toss_n_go}
          caption="toss 'n go is just a loop through the rapide and then rappel double strand"
        />
        <p>
          Setting up <GlossaryLink>courtesy rigging</GlossaryLink> over the
          first lip will eliminate this problem as well.
        </p>
        <p>
          One can also set the length of the rappel side so that the bend is
          past all edges then it can't be a problem, either. These solutions
          will not work in every circumstance, though.
        </p>
        <h4>Use a flat bend</h4>
        <p>
          In non-ideal circumstances, we can use a{' '}
          <GlossaryLink>flat bend</GlossaryLink> instead of a traditional bend.
          A flat bend is advantageous because it doesn't have anything that can
          get caught on the lip as it goes over. Examples of a flat bend are the{' '}
          <GlossaryLink>EDK</GlossaryLink>,{' '}
          <GlossaryLink>stacked overhand bend</GlossaryLink>,{' '}
          <GlossaryLink>flat double overhand bend</GlossaryLink>, and flat
          fisherman's bend.
        </p>
        <Image
          image={data.stacked_overhand_bend}
          caption="Stacked overhand bend used to combine two ropes"
        />
        <p>
          Flat bends aren't without disadvantages, though. All flat bends have
          can <GlossaryLink>capsize</GlossaryLink>, which is a very scary
          experience for the unfortunate rappeller. If the bend were to capsize
          enough times and the bend not have sufficient{' '}
          <GlossaryLink>tails</GlossaryLink>, the bend could roll right off the
          end of the rope!
        </p>
        <h3>Bend stuck in constriction</h3>
        <p>
          The types of constrictions I can think of are between a chockstone and
          the canyon wall, or where the anchor is far from the lip and the rope
          travels through a skinny section before reaching the lip.
        </p>
        <h4>Prevent bend from going through constriction</h4>
        <p>
          This is the best solution and the same as preventing the bend from
          going over an edge. Either go toss'n go or put the knot past the lip.
        </p>
        <h4>Rig another rappel</h4>
        <p>
          I discovered this solution the hard way. If you rappel over a
          chockstone and it looks like there's no way to <em>not</em> get the
          bend caught between the chockstone and canyon wall, you can likely
          just rig another rappel at that spot.
        </p>
        <h3>Stuck block</h3>
        <p>
          A stuck block is very similar to a stuck bend with all of the problems
          and solutions. Of course, the solution to removing the block would
          mean to go toss 'n go.
        </p>
        <h3>Block and bend on opposite sides of rigging</h3>
        <p>
          Yeah, I wish I didn't have to mention this one. Bend and block{' '}
          <em>must</em> be on the same side of the rigging otherwise the rope
          isn't going anywhere.
        </p>
        <Image image={data.bad_idea} />
        <h3>Safety still rigged</h3>
        <p>
          Along similar lines of thoughtlessness, remember to remove the safety
          before the last rappeller goes down. Safety is the "S" in my{' '}
          <Link to={route.page_step}>STEP acronym</Link>.
        </p>
        <Image
          image={data.safety}
          caption="Safety left on when last person descends"
        />
        <h2>Between Anchor and First Edge</h2>
        <p>
          This is where things get complicated. The majority of rope pull issues
          k occur between the anchor and that first edge, where you can't see
          the ropes and often can't change how they're oriented.
        </p>
        <h3>Pinched rappel side</h3>
        <Image
          image={data.opengraph}
          caption={
            <>
              Actual real-life stuck rope from{' '}
              <GlossaryLink>biner block</GlossaryLink> pinching{' '}
              <GlossaryLink>rappel side</GlossaryLink>
            </>
          }
        />
        <p>
          This happens when the pull side somehow ends up <em>on top</em> of the
          rappel side. Ways to prevent this are as follows.
        </p>
        <h4>Rig rappel side up and out of rapide</h4>
        <p>
          If the rapide doesn't twist when someone is on rappel (like it's
          attached to a chain), rig the rappel side by feeding it from the wall
          up through the rapide. The block, then, would go between the rapide
          and the canyon wall. This sets the block in the correct position so
          that when it's pulled it won't pinch the rappel side.
        </p>
        <h4>Keep rappel side and pull side separate</h4>
        <p>
          The simplest solution is to keep the lines separate for as long as
          possible. When you are going over that first lip, place the pull side
          in a different crack or <GlossaryLink>rope groove</GlossaryLink> than
          the rappel side. From there just keep them separated all the way down.
          Having someone at the bottom holding the pull side out of the way can
          help with this.
        </p>
        <p>
          It is possible to do this with DRT to a limited extent, even with both
          ropes weighted.
        </p>
        <h4>Intentionally place the rappel side above the pull side</h4>
        <p>
          In situations where it is impossible to separate the rappel side and
          pull side, the next best thing is to make a conscious effort to put
          the rappel side on the pull side. This is the natural thing to do
          since the pull side normally sits unweighted on the ground and the
          rappel side is folded onto the edge as the rappeller descends. Just
          make sure they don't accidentally get flipped around.
        </p>
        <h3>Free-spinning rapide</h3>
        <p>
          This refers to when the rapide is not resting on something while
          weighted and has the tendency to spin all on it's own and for whatever
          reason. I hate these. The best thing I have found is to see how they
          end up twisted when weighted and twist the rope down below
          accordingly.
        </p>
        <p>
          This solution often fails to completely remove all twists but the good
          news is that the rapide can freely spin when pulling the rope so the
          twists at the top are rarely a real problem.
        </p>
        <h2>Double Rope Technique</h2>
        <p>
          There are additional complications when rappelling DRT (
          <GlossaryLink>Double Rope Technique</GlossaryLink>) that mainly have
          to do with rope twist.
        </p>
        <h3>DRT ropes twisting in rappel device</h3>
        <p>
          This happens when the last rappeller uses a{' '}
          <GlossaryLink id="figure-8-rappel-device">
            figure 8 style rappel device
          </GlossaryLink>{' '}
          with DRT where the strands are not isolated.
        </p>
        <p>
          By far the best solution is to use a non-figure 8 rappel device such a{' '}
          <GlossaryLink>totem</GlossaryLink> in throttle mode. It completely
          eliminates this class of problems and forces the ropes to be untwisted
          when the rappeller reaches the bottom.
        </p>
        <Image image={data.throttle} caption="Totem rigged throttle mode" />
        <p>
          Other solutions which work to varying degrees of effectiveness are to
          clip a sling/carabiner combo into one of the strands above the rappel
          device. As the rappel descends and the rope twists in the device, the
          carabiner will twist around the ropes and it will be easy to determine
          how much the rope has twisted when the rappeller gets to the bottom.
          The sling can create a lot of additional friction if it twists a lot,
          though, and can even act as a friction hitch and stop the rappeller.
          For this reason I don't recommend this solution.
        </p>
        <p>
          The rappeller can place their finger in between the ropes and force
          out any twists as they are coming down. This pretty much eliminates
          any fun that is involved with the rappel since you spend your time
          fighting the rope the whole way down. The rappeller could also add a
          carabiner to their leg loop and clip it into only one of the ropes to
          kind of accomplish the same thing. I haven't tested this yet method,
          though.
        </p>
        <p>
          My usual solution, which is still terrible, is to count the direction
          and number of "bumps" as I go down, each bump corresponding to one
          half-twist. If you're below me, you might hear me mumble, "Three
          counter-clockwise", meaning once I get down, the ropes have to twist
          back three half-turns counter-clockwise for them to be untwisted
          again.
        </p>
        <h3>DRT spinning on free hang</h3>
        <p>
          When <GlossaryLink>free hanging</GlossaryLink> it is common for the
          rappeller to spin a few times on the way down. If the rappeller is
          going DRT or they have the pull side bagged and clipped to themselves
          this spinning can cause the two ropes to twist on the way down.
        </p>
        <p>
          My preferred solution to account for this is to count the number of
          times I spin going down. As soon as I touch down I then spin in the
          opposite direction that number of times. Then I de-rig and the two
          ropes should be correctly untwisted.
        </p>
        <h2>Simplify the Pull</h2>
        <p>
          Once everyone is down and all the twists are removed, there are still
          several things that should be done to simplify the pull.
        </p>
        <h3>Twists in the rappel side</h3>
        <p>
          If the rappel side length wasn't set, it will likely have rope twists
          in it. As the rope is being pulled, someone should be holding the
          rappel side and remove any twists as the line goes up.
        </p>
        <Image
          image={data.rope_twist}
          caption="Rope twist jammed in the rapide"
        />
        <h3>Back away from the rappel</h3>
        <p>
          Often you can eliminate drag on the pull (and prevent rope grooves) by
          stepping back from the drop and pulling at an angle besides straight
          down.
        </p>
        <h2>Retrievable Rigging</h2>
        <p>
          <GlossaryLink>Retrievable rigging</GlossaryLink> always has extra{' '}
          <em>stuff</em> that must come down besides just a bend or block.{' '}
          <GlossaryLink>Toggles</GlossaryLink> have the toggle and the twisted
          rappel side that needs to go around the anchor without getting caught.{' '}
          <GlossaryLink>Sand traps</GlossaryLink> have the trap and also must
          have a place for the sand to empty to either side before it can be
          pulled down. <GlossaryLink>Water anchors</GlossaryLink> have the pull
          cord and must have room to empty the water bladder.{' '}
          <GlossaryLink>Hooks</GlossaryLink> are especially finicky on the pull
          and are rarely used for this reason. Even with rope-only systems such
          as the <GlossaryLink>CEM</GlossaryLink> there will be a looped rope
          and potentially lots of twists that must come down.
        </p>
        <p>
          Each of these have special considerations that are beyond the scope of
          this article.
        </p>
        <h2>Rope Got Stuck anyway</h2>
        <p>
          Don't ascend if either strand is out of reach.{' '}
          <Link to={route.page_about}>Let me know</Link> what went wrong and
          what you think you should have done.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('pull')(Canyoneering_Pull);

export const query = graphql`
  query {
    opengraph: file(relativePath: { eq: "images/opengraph/pull.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    toss_n_go: file(
      relativePath: { eq: "images/canyoneering/pull/toss-n-go.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    stacked_overhand_bend: file(
      relativePath: { eq: "images/canyoneering/pull/stacked-overhand-bend.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bad_idea: file(
      relativePath: { eq: "images/canyoneering/pull/bad-idea.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    safety: file(relativePath: { eq: "images/canyoneering/pull/safety.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    throttle: file(
      relativePath: { eq: "images/canyoneering/pull/throttle.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    rope_twist: file(
      relativePath: { eq: "images/canyoneering/pull/rope-twist.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
